import React from 'react';
import {
    Box,
    Button,
    Divider,
    VStack,
    Stack,
    Heading,
    Flex,
    List,
    ListItem,
    ListIcon,
    useClipboard,
} from '@chakra-ui/react';
import { MdPinDrop, MdPhoneAndroid } from 'react-icons/md';
import { HiOutlineMail } from 'react-icons/hi';

const rquisites = [
    {
        value:
            'Sabiedrība ar ierobežotu atbildību "REKLĀMAS SŪTĪJUMI UN SERVISS"',
    },
    { value: 'Kreimeņu iela 6-2, Rīga, LV-1015' },
    { label: 'Reg.Nr.', value: '50003640271' },
    { label: 'PVN.Nr.', value: 'LV50003640271' },
    { label: 'Banka', value: 'AS Swedbank' },
    { label: 'Konts', value: 'LV91HABA0551005140155' },
    { label: 'Kods', value: 'HABALV22' },
];

// markup
const IndexPage = () => {
    const { hasCopied, onCopy } = useClipboard(
        rquisites.map(({ value }) => value).join('\n')
    );

    return (
        <VStack minH="100vh" w="100vw" bg="gray.700" justifyContent="center" pt={10} pb={10}>
            <Box color="white" flexDirection="column">
                <Heading>pakosana.lv</Heading>

                <Divider mt={3} mb={3} colorScheme="white" />

                <Stack direction={{ base: "column", lg: "row" }}>
                    <Box
                        borderRadius={'md'}
                        minH={200}
                        bg="gray.600"
                        p={5}
                        minW={{ base: "100%", lg: "400px" }}
                    >
                        <Heading size={2} mb={2}>
                            Juridiskā adrese
                        </Heading>
                        <List spacing={3}>
                            <ListItem>
                                <ListIcon as={MdPinDrop} color="green.500" />
                                Kreimeņu iela 6-2, Rīga, LV-1015
                            </ListItem>
                            <ListItem>
                                <ListIcon
                                    as={MdPhoneAndroid}
                                    color="green.500"
                                />
                                +371 2540 1213
                            </ListItem>
                            <ListItem>
                                <ListIcon
                                    as={HiOutlineMail}
                                    color="green.500"
                                />
                                info[at]pakosana.lv
                            </ListItem>
                        </List>
                    </Box>
                    <Box
                        borderRadius={'md'}
                        minH={200}
                        bg="gray.600"
                        p={5}
                        minW={{ base: "100%", lg: "400px" }}
                    >
                        <Heading size={2} mb={2}>
                            Noliktavas adrese
                        </Heading>
                        <List spacing={3}>
                            <ListItem>
                                <ListIcon as={MdPinDrop} color="green.500" />
                                Dēļu iela 4i, Rīga, LV-1004
                            </ListItem>
                            <ListItem>
                                <ListIcon
                                    as={MdPhoneAndroid}
                                    color="green.500"
                                />
                                +371 2540 1213
                            </ListItem>
                            <ListItem>
                                <ListIcon
                                    as={HiOutlineMail}
                                    color="green.500"
                                />
                                info[at]pakosana.lv
                            </ListItem>
                        </List>
                    </Box>
                </Stack>

                <Flex>
                    <Box p="1"></Box>
                </Flex>

                <Flex
                    borderRadius={'md'}
                    bg="gray.500"
                    justifyContent="space-between"
                    color="white"
                    padding="5"
                    flexDirection="row"
                >
                    <List mb={3} spacing={2}>
                        {rquisites.map(({ label, value }) => (
                            <ListItem>
                                {`${label ? `${label}: ` : ''}${value}`}
                            </ListItem>
                        ))}
                    </List>
                    <Box ml={3}>
                        <Button onClick={onCopy} colorScheme="green">
                            {hasCopied ? 'Nokopēts' : 'Kopēt'}
                        </Button>
                    </Box>
                </Flex>
            </Box>
        </VStack>
    );
};

export default IndexPage;
